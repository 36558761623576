import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
import { appsignal } from "../../lib/appsignal";
import { installErrorHandler } from "@appsignal/stimulus"

const application = Application.start()
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application


registerControllers(application, controllers)
installErrorHandler(appsignal, application)
